import { NgModule }                                                          from '@angular/core';
import { FormatRegistry }                                                    from '@cs/common/culture';
import { Calculations, DataGridHelpers, DataGridRuleEnforcer, GridDataCell } from '@cs/components/data-grid';
import { isNullOrUndefined, isString }                                       from '@cs/core';
import { CustomerRoutingModule }                                             from '@cs/customer/customer-routing.module';
import { WFSFormatRegistry }                                                 from '@cs/customer/wfs-format-registry';
import { CustomerDynamicButtonsAgent }           	                           from '@cs/customer/customer-dynamic-buttons.service';

import { DefaultNavbarIconsFactory, SHELL_NAVBAR_ICONS_REPO }                from '@cs/performance-manager';

export function CustomerNavbarIconsFactory() {
	const iconsLib = DefaultNavbarIconsFactory();
	return iconsLib;
}

/**
 * This bootstraps any angular customer specific code;
 */
@NgModule({
						declarations: [],
						imports:      [CustomerRoutingModule],
						providers:    [{provide: SHELL_NAVBAR_ICONS_REPO, useFactory: CustomerNavbarIconsFactory},
													 {provide: FormatRegistry, useClass: WFSFormatRegistry},
													 {provide: CustomerDynamicButtonsAgent, useClass: CustomerDynamicButtonsAgent}],
						exports:      [CustomerRoutingModule]
					})
export class CustomerInitModule {
	constructor() {
		Calculations.addCalculationMethod('matched', (values: Array<GridDataCell>, currentCell: GridDataCell) => {
			let total    = 0;
			let hasValue = false;

			const filtered = DataGridRuleEnforcer.find({match: currentCell.calculation.match} as any, values, currentCell.calculation.partialMatch);

			filtered.forEach(x => {
				if (x.id === currentCell.id)
					return;

				let val = 0;

				// check if value is a string if so try to parse it as a string because it could be manual input
				if (!isNullOrUndefined(x.value) && x.value !== '') {
					val      = isString(x.value)
										 ? parseFloat(x.value)
										 : x.value;
					total += (val * x.calculation.aggregationFactor);
					hasValue = true;
				}
			});

			if (!hasValue)
				total = null;

			return total;

		});

		Calculations.addCalculationMethod('HoursToReduce', (values: Array<GridDataCell>, currentCell: GridDataCell) => {
			let total    = 0;

			const filtered = DataGridRuleEnforcer.find({match: currentCell.calculation.match} as any, values, currentCell.calculation.partialMatch);

			const totalProductiveHoursCellWithChanges = filtered.find(value => DataGridHelpers.hasAllKeys(value.keys, {'idWorkforceOptItem': 56}));
			const totalProductiveHoursCellSystem      = filtered.find(value => DataGridHelpers.hasAllKeys(value.keys, {'idWorkforceOptItem': 90}));
			const totalVolumeCellWithChanges          = filtered.find(value => DataGridHelpers.hasAllKeys(value.keys, {'idIndicator': 8000}, ['idWorkforceOptItem']));
			const totalVolumeCellSystem               = filtered.find(value => DataGridHelpers.hasAllKeys(value.keys, {'idWorkforceOptItem': 91}));

			if (totalProductiveHoursCellWithChanges && totalVolumeCellWithChanges) {
				const totalProductiveHours1 = Number(totalProductiveHoursCellWithChanges.value);
				const totalProductiveHours2 = Number(totalProductiveHoursCellSystem.value);
				const totalVolume1          = Number(totalVolumeCellWithChanges.value);
				const totalVolume2          = Number(totalVolumeCellSystem.value);

				// Necessary mitigation of hours to maintain productivity
				total = totalProductiveHours1 - ((totalProductiveHours2 * totalVolume1) / totalVolume2);

				// Since Total Productive Hours * Total Volume / Total Volume simplifies to Total Productive Hours,
				total = total > 0
								? total
								: 0;
			}

			return total;

		});

		Calculations.addCalculationMethod('HoursToAdd', (values: Array<GridDataCell>, currentCell: GridDataCell) => {
			let total    = 0;

			const filtered = DataGridRuleEnforcer.find({match: currentCell.calculation.match} as any, values, currentCell.calculation.partialMatch);

			const totalProductiveHoursCellWithChanges = filtered.find(value => DataGridHelpers.hasAllKeys(value.keys, {'idWorkforceOptItem': 56}));
			const totalProductiveHoursCellSystem      = filtered.find(value => DataGridHelpers.hasAllKeys(value.keys, {'idWorkforceOptItem': 90}));
			const totalVolumeCellWithChanges          = filtered.find(value => DataGridHelpers.hasAllKeys(value.keys, {'idIndicator': 8000}, ['idWorkforceOptItem']));
			const totalVolumeCellSystem               = filtered.find(value => DataGridHelpers.hasAllKeys(value.keys, {'idWorkforceOptItem': 91}));

			if (totalProductiveHoursCellWithChanges && totalVolumeCellWithChanges) {
				const totalProductiveHours1 = Number(totalProductiveHoursCellWithChanges.value);
				const totalProductiveHours2 = Number(totalProductiveHoursCellSystem.value);
				const totalVolume1          = Number(totalVolumeCellWithChanges.value);
				const totalVolume2          = Number(totalVolumeCellSystem.value);

				// Necessary mitigation of hours to maintain productivity
				total = ((totalProductiveHours2 * totalVolume1) / totalVolume2) - totalProductiveHours1;

				// Since Total Productive Hours * Total Volume / Total Volume simplifies to Total Productive Hours,
				total = total > 0
								? total
								: 0;
			}

			return total;

		});
	}
}
